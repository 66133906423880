import React, { Component } from "react";
import config from "../../../data/SiteConfig";
import SimpleCentered from "../../components/Footers/SimpleCentered";

class Footer extends Component {
  render() {
    const { config } = this.props;
    return (
      <SimpleCentered />
    );
  }
}

export default Footer;
