const config = {
  siteTitle: "Sudoku.ie", // Site title.
  siteTitleShort: "Sudoku", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "Sudoku.ie", // Alternative site title for SEO.
  siteLogo: "/logos/logo-512.png", // Logo used for SEO and manifest.
  siteImage: "/images/sudoku.jpg",
  siteUrl: "https://www.sudoku.ie", // Domain of your website without pathPrefix.
  siteTagLine: "Play Sudoku for free on Ireland's Sudoku website.",
  pathPrefix: "", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: "Play Sudoku for free on Ireland's Sudoku website.", // Website description used for RSS feeds/meta description tag.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  postsPerPage: 3, // Amount of posts displayed per listing page.
  socialFacebook: "https://www.facebook.com/sudoku.ie", // Optionally renders "Follow Me" in the UserInfo segment.
  socialInstagram: "https://www.instagram.com/", // Optionally renders "Follow Me" in the UserInfo segment.  
  socialTwitter: "https://www.twitter.com/", // Optionally renders "Follow Me" in the UserInfo segment.
  themeColor: "#EDF2F7", // Used for setting manifest and progress theme colors.
  backgroundColor: "#e0e0e0", // Used for setting manifest background color.
  headerBackgroundLight:"bg-white shadow",
  footerBackgroundLight: "bg-white",
  headerBackgroundDark:"bg-gray-900",
  footerBackgroundDark: "bg-gray-900",
  fontFamily: "DM Sans",
  blogDirectory: "blog",
  blogTitle: "Updates",
  blogDecription: "Latest updates from Ireland's Sudoku website.",
  blogTagsDirectory: "blog/tags",
  blogCategoriesDirectory: "blog/categories",
  blogReadMoreText: "Read full story",
  emailSubscribeButtonText: "Subscribe",
  emailSubscribeTitleText: "Sign up for our newsletter",
  emailSubscribeDescriptionText: "Sure why not, what's there to lose lads?",
  emailSubscribePrivacyText: "We care about the protection of your data. Read our",
  emailSubscribePrivacyCTA: "Privacy Policy",

// Top Menu

  mainMenu: [

  ],  
  mainMenuCTAText: "Start",
  mainMenuCTAPath: "/",
  mainMenuSearchText: "Find what you need",
  mainMenuSearchButtonText: "Search",

// Footer menus  
  firstFooterMenuTitle: "Solutions",
  firstFooterMenu: [
    {
      label: 'Articles',
      path: '/'
    },
    {
      label: 'Subscribe Confirm',
      path: '/subscribe-confirm'
    },
    {
      label: 'Subscribe Thanks',
      path: '/subscribe-thanks'
    }
  ],  

  secondFooterMenuTitle: "Support",  
  secondFooterMenu: [
    {
      label: 'Articles',
      path: '/'
    },
    {
      label: 'Subscribe Confirm',
      path: '/subscribe-confirm'
    },
    {
      label: 'Subscribe Thanks',
      path: '/subscribe-thanks'
    }
  ],   

  thirdFooterMenuTitle: "Company",  
  thirdFooterMenu: [
    {
      label: 'About',
      path: '/about'
    },
    {
      label: 'Blog',
      path: '/blog'
    },
    {
      label: 'Subscribe Thanks',
      path: '/subscribe-thanks'
    }
  ],    

  fourthFooterMenuTitle: "Legal",
  fourthFooterMenu: [
    {
      label: 'Terms and Conditions',
      path: '/terms'
    },
    {
      label: 'Privacy Policy',
      path: '/privacy'
    },
    {
      label: 'Cookie Policy',
      path: '/cookies'
    }
  ],    
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);


module.exports = config;
