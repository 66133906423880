import React from "react"
const config = require("../../../data/SiteConfig")
const SiteNavLogo = () => {

  return (
    <a
      style={{
        display: `flex`,
      }}

      href="/"
    >

      <img src="/logos/logo-192.png" className="h-10" alt="Sudoku.ie Logo" />
        <div className="pl-2 pt-1 h-8 w-auto text-2xl font-bold align-middle text-blue-800">
            {config.siteTitle}
        </div>      
    </a>
  )
}

export default SiteNavLogo