import React, { Component } from "react";
import Simple from "../../components/Navbars/Simple";

class Header extends Component {
  render() {
    const { config } = this.props;
    return (
        <Simple />
    );
  }
}

export default Header;
