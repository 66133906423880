import React, { Component } from "react";
import SiteNavLogo from "../../components/Logos/SiteNavLogo";
import { Link } from "gatsby";
const config = require("../../../data/SiteConfig");

class Simple extends Component {

  render() {
    return (
        <nav className={config.headerBackgroundLight}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
            <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                <SiteNavLogo />
                </div>
                <div className="hidden sm:ml-6 sm:flex">
                            {config.mainMenu.map((item) => (
                                <Link 
                                    to={item.path} 
                                    className={'ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out'}
                                    activeClassName={'inline-flex items-center px-1 pt-1 border-b-2 border-indigo-500 text-sm font-medium leading-5 text-gray-900 focus:outline-none focus:border-indigo-700 transition duration-150 ease-in-out'}
                                    >
                                    {item.label}
                                </Link>          
                            ))}               
                </div>
            </div>

            </div>
        </div>



        </nav>


    );
  }
}

export default Simple;
