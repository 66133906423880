import React from "react";
import Helmet from "react-helmet";
import config from "../../data/SiteConfig";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import "./index.css";

export default class MainLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      
      <div className="layout-container">
        <Helmet>
          <meta name="description" content={config.siteDescription} />
          <html lang="en" />
          <script defer data-domain="sudoku.ie" data-api="https://go.sudoku.ie/trajectory/api/event" src="https://go.sudoku.ie/trajectory/js/script.js"></script>
        </Helmet>
        <Header />  
        <div className="relative overflow-hidden pt-2">
            <div className="relative h-full max-w-screen-xl mx-auto">        
            {children}
            </div>
        </div>    
        <Footer config={config} />           
      </div>
                
    );
  }
}
