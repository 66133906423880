import React, { Component } from "react";
const config = require("../../../data/SiteConfig")

class SimpleCentered extends Component {
  render() {
    return (

        <div className={config.footerBackgroundLight}>
        <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
            <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
            <div className="px-5 py-2">
                <a href="/terms" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                Terms &amp; Conditions
                </a>
            </div>
            <div className="px-5 py-2">
                <a href="/privacy" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                Privacy Policy
                </a>
            </div>
            <div className="px-5 py-2">
                <a href="/cookies" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                Cookie Policy
                </a>
            </div>
            </nav>
            <div className="mt-8 flex justify-center">
            <a href={config.socialFacebook} target="_blank" className="text-gray-400 hover:text-gray-500" rel="noopener">
                <span className="sr-only">Facebook</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd"/>
                </svg>
            </a>
            </div>
            <div className="mt-8">
            <p className="text-center text-base leading-6 text-gray-500">
                &copy; {(new Date().getFullYear())} {config.siteTitle}
            </p>
            </div>
        </div>
        </div>





    );
  }
}

export default SimpleCentered;
